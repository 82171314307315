var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"d-flex align-start"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('v-tooltip',{attrs:{"color":"white","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3 mt-2",attrs:{"color":_vm.wiringsError ? 'red' : ''}},on),[_vm._v(" mdi-alert-circle ")])]}}])},[_c('span',[_vm._v("В проект должна быть создана хотя бы одна проводка (плановый доход по проекту).")])])],1),(!_vm.wirings.length)?_c('div',[_c('span',{style:({color: _vm.wiringsError ? 'red' : 'inherit'})},[_vm._v("Список проводок пуст.")])]):_vm._e(),(_vm.wirings.length)?_c('WiringsTable',{attrs:{"wirings":_vm.wirings}}):_vm._e(),_c('WiringDialog',{attrs:{"resetKey":_vm.wirings.length,"yearsList":_vm.yearsList,"project":_vm.project,"notSaveWiring":true,"title":"Добавление проводки"},on:{"select":function($event){return _vm.handleAddWiring($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var disabled = ref.disabled;
var attrs = ref.attrs;
var on = ref.on;
var click = ref.click;
return [_c('v-btn',{attrs:{"disabled":disabled,"bind":attrs,"on":on},on:{"click":click}},[_vm._v(" Добавить проводку")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }