<template>
    <v-data-table disable-sort :headers="headers" :items="wiringsItems" :items-per-page="10">

    </v-data-table>
</template>

<script>
import { wiringTypeDirDict, wiringType } from '@/components/register/WireDict';

export default {
    name: 'WiringsTable',
    props: ['wirings'],
    data() {
        return {
            headers: [
                {
                    text: 'Сумма',
                    value: 'sum',
                    width: 100
                },
                {
                    text: 'План/факт',
                    value: 'type'
                },
                {
                    text: 'Нал/безнал',
                    value: 'paymentMethod'
                },
                {
                    text: 'Дата оплаты',
                    value: 'paymentDate'
                },
                {
                    text: 'Доход/расход',
                    value: 'typeDir'
                },
                {
                    text: 'Фонд',
                    value: 'fund'
                },
                {
                    text: 'Статья',
                    value: 'article'
                },
                {
                    text: 'Организация',
                    value: 'organization'
                },
                {
                    text: 'Контрагент',
                    value: 'contractor'
                },
                {
                    text: 'Год по бюджету',
                    value: 'budgetYear'
                }
            ]
        }
    },
    computed: {
        wiringsItems() {
            return this.wirings.map((wiring) => {
                const {sum, article, fund, paymentDate, paymentMethod, budgetYear, contractor, organization, type, typeDir} = wiring;
                const formatter = new Intl.NumberFormat("ru", { style: "currency",currency: "RUB" });
                return {
                sum: formatter.format(sum),
                article: article.article,
                fund: fund.fund,
                paymentDate,
                paymentMethod: paymentMethod.text,
                budgetYear,
                contractor: contractor ? contractor.shortName : null,
                organization: organization.shortName || organization?.fullName,
                type: wiringType[type],
                typeDir: wiringTypeDirDict[typeDir].title
            }})
        }
    }
}
</script>