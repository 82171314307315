<template>
    <section>
        <div class="d-flex align-start">
          <h2>{{ title }}</h2>
          <v-tooltip color='white' right>
            <template v-slot:activator="{ on }">
              <v-icon 
                  v-on="on"
                  class="ml-3 mt-2"
                  :color="wiringsError ? 'red' : ''"
                  >
                  mdi-alert-circle
                </v-icon>
            </template>
            <span>В проект должна быть создана хотя бы одна проводка (плановый доход по проекту).</span>
          </v-tooltip>
        </div>
        <div v-if="!wirings.length">
          <span :style="{color: wiringsError ? 'red' : 'inherit'}">Список проводок пуст.</span>
        </div>
        <WiringsTable v-if="wirings.length" :wirings="wirings"></WiringsTable>
        <WiringDialog 
          :resetKey="wirings.length" 
          @select="handleAddWiring($event)" 
          :yearsList="yearsList" 
          :project="project" 
          :notSaveWiring="true"
          title="Добавление проводки" >
          <template v-slot="{ disabled, attrs, on, click }">
            <v-btn
              :disabled="disabled"
              :bind="attrs"
              :on="on" 
              @click=click>
              Добавить проводку</v-btn>
          </template>
        </WiringDialog>
      </section>
</template>

<script>
import WiringDialog from '@/components/wirings/WiringDialog';
import WiringsTable from './WiringsTable';
import { wPost, wGet, wDec } from '@/modules/Wirings'
import dayjs from 'dayjs'

export default {
    name: 'Wirings',
    components: { WiringDialog, WiringsTable },
    props: ['title', 'project', 'wiringsError'],
    data() {
        return { 
            oldWirings: [],
            newWirings: [],
            error: this.wiringsError
         }
    },
    methods: {
        handleAddWiring(wData) {
            wData.id = (this.newWirings.at(-1)?.id || 0) + 1
            this.newWirings.push(wData);
        },
        handleDeleteNewWiring(delId) {
            this.newWirings = this.newWirings.filter(({ id }) => id !== delId);
        },
        checkWirings() {
          if(!this.wirings?.length) {
            this.wirings.error = 'Список проводок не должен быть пустым';
            return false;
          }
          return true
        },
        async postWirings(projectID) {
          const today = dayjs().format('YYYY-MM-DD');
          for(let i = 0; i < this.newWirings.length; i++) {
            this.newWirings[i].createDate = today;
            this.newWirings[i].project.id = projectID;
            await wPost(this.actualYear, JSON.stringify(this.newWirings[i]))  
          }
        }
    },
    computed: {
      wirings() {
        return [...this.oldWirings, ...this.newWirings]
      },
      actualYear() {
        return this.project?.year || new Date().getFullYear();
      },
      yearsList() {
        const year = new Date().getFullYear();
        return [year - 1, year, year + 1, year + 2];
      },
    },

    watch: {
      'wirings': function() {
        this.$emit('getWirings', this.wirings);
      },
      'newWirings': function() {
        if(this.newWirings?.length > 0) this.wiringsError = ''
        this.$emit('postWirings', this.postWirings);
      },
    },
    async beforeMount() {
      if(this.project?.id) {
        const encodedWiringsList = await wGet(this.project.year);
        const wiringsList = await wDec(encodedWiringsList);
        wiringsList.sort((a, b) => b.id - a.id);
        this.oldWirings = wiringsList.map(({data}) => data).filter((data) => data?.project?.id?.toString() === this.project.id);
      }
    }
}

</script>