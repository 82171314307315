<template>
  <v-container>
    <h1>
      <span>Редактирование информации о проекте</span>
    </h1>

    <v-form ref="form" v-if="apiLoaded" class="d-flex flex-column" style="gap: 70px">
      <section class="form-wrap d-flex flex-column" style="gap: 10px">
        <v-row>
          <v-col cols="12"><h2>Общая информация</h2></v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <p class="error--text mb-2">
              <v-icon
                  color='error'
                  >
                  mdi-alert-circle
                </v-icon>
                Внимание! После создания проекта поле "Год проекта" будет недоступно для редактирования!</p>
            <v-select
              label="Год проекта *"
              dense
              outlined
              v-model="projectCommonInfo.year"
              @change="changeWiringsAfterChangeProject"
              :items="yearsList"
              :rules="[(v) => !!v || 'Поле не должно быть пустым']"
              :disabled="!!this.projectId"
            ></v-select>
            
          </v-col>
        </v-row>

        <!--        <v-row>-->
        <!--          <v-col cols="12">-->
        <!--            <v-text-field-->
        <!--              label="Номер проекта"-->
        <!--              dense-->
        <!--              outlined-->
        <!--              v-model="projectCommonInfo.number"-->
        <!--              :rules="[-->
        <!--                (v) =>-->
        <!--                  (v != null && v.length > 0) || 'Поле не должно быть пустым',-->
        <!--                (v) =>-->
        <!--                  (v && v.length <= 32) || 'Максимальное число символов - 32',-->
        <!--              ]"-->
        <!--            ></v-text-field>-->
        <!--          </v-col>-->
        <!--        </v-row>-->

        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Полное название проекта"
              dense
              outlined
              v-model.trim="projectCommonInfo.fullName"

            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Короткое название проекта *"
              dense
              outlined
              v-model.trim="projectCommonInfo.shortName"
              @change="changeWiringsAfterChangeProject"
              :rules="[
                (v) => {
                  if(!v) return 'Поле не должно быть пустым'
                  if(v.length > 100) return 'Максимальное число символов - 100'
                  return true
                }
              ]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Статус *"
              dense
              outlined
              :items="projectStatusDict"
              item-text="title"
              item-value="code"
              v-model="projectCommonInfo.status"
              :rules="[(v) => !!v || 'Поле не должно быть пустым']"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <date-field
              label="Дата начала"
              v-model.trim="projectCommonInfo.startDate"
              min-date="2000-01-01"
              max-date="2099-12-31"
            ></date-field>
          </v-col>
          <v-col cols="6">
            <date-field
              label="Дата окончания"
              v-model.trim="projectCommonInfo.endDate"
              min-date="2000-01-01"
              max-date="2099-12-31"
            ></date-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Руководитель проекта *"
              :items="offerExperts"
              item-text="fullName"
              item-value="id"
              v-model="projectCommonInfo.offerExpert"
              outlined
              :rules="[v => !!v || 'Поле не должно быть пустым']"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <register-select-field
          field-title="Заказчик *"
          dialog-title="Выбор контрагент"
          :reg-config="counterpartiesRegisterConfig"
          page-size="10"
          default-tab="0"
          :text-function="(item) => item.fullName || ''"
          text-search-attribute="fullName"
          :value-object="projectCommonInfo.contractor"
          @select="
            projectCommonInfo.contractor = $event;
            $forceUpdate();
          "
          @clear="
            projectCommonInfo.contractor = null;
            $forceUpdate();
          "
          :not-null="true"
        >
        </register-select-field>

        <v-row>
          <v-col cols="12">
            <v-textarea
              outlined
              label="Описание проекта"
              v-model.trim="projectCommonInfo.description"
              :rules="[
                (v) =>
                  !v ||
                  v.length <= 12000 ||
                  'Максимальное число символов - 12000',
              ]"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Направление *"
              :items="supMarketDict"
              item-text="title"
              item-value="code"
              v-model="projectCommonInfo.markets"
              :rules="[(v) => !!v.length || 'Поле не должно быть пустым']"
              multiple
              chips
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="Без договора"
              v-model="projectCommonInfo.noContract"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="projectCommonInfo.noContract">
          <v-col cols="11" >
            <v-select
              :items="noContractProjectItems"              
              item-text="title"
              item-value="code"
              v-model="projectCommonInfo.noContractProject"
              :rules="[(v) => !projectCommonInfo.noContract || (!!v || 'Поле не должно быть пустым')]"
              outlined
              clearable
            ></v-select>
          </v-col>
          <v-col cols="1" >
            <v-tooltip color="white" right max-width="300">
              <template v-slot:activator="{ on }">
                <v-icon 
                  v-on="on" 
                  class="mt-4">
                  mdi-alert-circle
                </v-icon>
              </template>
              <span>Проставляется «весь проект», в случае если договор по данному проекту не предусмотрен, «часть проекта» - в случае, если договор не предусмотрен по части проекта</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </section>

      <Contracts 
        :disabled="!!projectCommonInfo.noContractProject" 
        @changeContracts="customerContractsChange" 
        :contracts="projectCommonInfo.customerContracts" 
        :config="customerContractsRegisterConfig" 
        title="Договоры с заказчиками"></Contracts>

      <Contracts 
        @changeContracts="supplierContractsChange" 
        :contracts="projectCommonInfo.supplierContracts" 
        :config="supplierContractsRegisterConfig" 
        title="Договоры с подрядчиками"></Contracts>
     


      <Wirings
        title="Финансы"
        :project="projectCommonInfo"
        :wiringsError="wiringsError"
        @postWirings="postWirings = $event"
        @getWirings="wirings = $event">
        
      </Wirings>


      <div class="mt-12">
        <v-btn outlined @click="cancel" class="mr-5">Отменить</v-btn>
        <v-btn @click="saveProject">Сохранить</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { projectStatusDict, supMarketDict, noContractProjectItems } from '@/modules/NSI';
import DateField from '@/components/elements/DateField';
import { loadDataToObject } from '@/modules/CommonUtils';
import api from '@/modules/api';
import { projectKeyHolderNew } from '@/modules/KeysHolder';
import {
  customerContractsRegisterConfig, 
  supplierContractsRegisterConfig,
  allCounterpartiesRegisterConfig,
  customerCounterpartiesRegisterConfig,
  supplierCounterpartiesRegisterConfig,
} from '@/components/register/RegisterConfigs';
import RegisterSelectField from '@/components/register/RegisterSelectField';
import Contracts from './Contracts'
import Wirings from './Wirings'
export default {
  name: 'ProjectEditComponent',
  components: { RegisterSelectField, DateField, Contracts, Wirings },
  data() {
    return {
      
      projectCommonInfo: { 
        year: new Date().getFullYear(),
        supplierContracts: [],
        customerContracts: [],
        contractor: undefined,
      },

      projectStatusDict,
      supMarketDict,
      noContractProjectItems,
      offerExperts: undefined,
      
      rules: {
        select: [(v) => !!v || 'Обязательное поле.'],
      },
      counterpartiesRegisterConfig: {
        tabs: [
          {
            title: 'Все организации',
            config: allCounterpartiesRegisterConfig,
          },
          {
            title: 'Заказчики',
            config: customerCounterpartiesRegisterConfig,
          },
          {
            title: 'Подрядчики',
            config: supplierCounterpartiesRegisterConfig,
          },
        ],
      },

      customerContractsRegisterConfig,
      supplierContractsRegisterConfig,

      apiLoaded: false,
      apiError: false,
      postWirings: null,
      wirings: null,
      wiringsError: false
    };
  },
  methods: {
    loadData: loadDataToObject,

    changeWiringsAfterChangeProject() {
      if(!this.wirings) return
      this.wirings?.forEach((w) => {
        w.budgetYear = this.projectCommonInfo.year;
        w.project.name = this.projectCommonInfo.shortName;
      })
    },

    supplierContractsChange(newContracts) {
      this.projectCommonInfo.supplierContracts = newContracts;
    },
    customerContractsChange(newContracts) {
      this.projectCommonInfo.customerContracts = newContracts;
    },

    async saveProject() {
      if (!this.$refs.form.validate()) {
        console.log('no validation form refs')
        return;
      }
      if (!this.checkForm()) {
        console.log('no validation checkForm')
        return;
      }
      const encryptedData = await projectKeyHolderNew.encode(JSON.stringify(this.projectCommonInfo))

      let req;
      if (this.projectId) {
        req = await api.putJson('/supmain/projects/', {
          id: this.projectId,
          data: encryptedData,
          year: this.projectCommonInfo.year,
        });
      } else { 
        req = await api.postJson('/supmain/projects/', {
          data: encryptedData,
          year: this.projectCommonInfo.year,
        });
      }
      if (req.ok) {
        try {
          this.projectCommonInfo['id'] = req.payload.id;
          await this.postWirings(req.payload.id);
        } catch(e) {
          console.error('Ошибка в создании проводки. Проводки не сохранились.')
        }
         
        this.$router.push({ path: '/project/' + req.payload.id });
      }
    },
    checkForm() {
      if(this.wirings?.length) return true
      this.wiringsError = true
    },
    cancel() {
      if (this.projectId != null) {
        this.$router.push({ path: '/project/' + this.projectId });
      } else {
        this.$router.push({ path: '/projects/' });
      }
    },
  },

  computed: {
    yearsList() {
      const year = new Date().getFullYear() + 5;
      const years = Array(10).fill().map((_, i) => year - i);
      return years.reverse();
    },
  },

  watch: {
    'projectCommonInfo.noContract': function() {
      if(this.projectCommonInfo.noContract) this.projectCommonInfo.noContractProject = 'fullProject'
      else delete this.projectCommonInfo.noContractProject
    },
  },
  async beforeMount() {
    if (!(await projectKeyHolderNew.checkKey())) return;
    await this.loadData(
        '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
        'offerExperts',
        true
    ),

    this.projectId = this.$route.params.id;

    if (this.projectId) {
      await this.loadData(
        '/supmain/projects/' + this.projectId,
        'encrypted',
        false
      );
      this.projectCommonInfo = await projectKeyHolderNew.decode(
        this.encrypted.data
      );
      this.projectCommonInfo.id = this.projectId;

    }
    this.apiLoaded = true;
  },
};
</script>
