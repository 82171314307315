<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

        <ProjectEditComponent/>

    </transition>
  </div>
</template>

<script>

import ProjectEditComponent from '@/components/projects/ProjectEdit/ProjectEditComponent.vue'

export default {
  name: 'ProjectEdit',
  components: {
    ProjectEditComponent
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>
