<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn 
            :disabled="disabled"
            v-bind="attrs"
            v-on="on" 
            class="mt-4" 
            @click='dialog = true'>
                Добавить договор</v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">{{title}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <main><register :config="{tabs: [{title: 'Договоры', config: regConfig}]}" select-mode="true" @select="$emit('select', $event); dialog = false" ></register></main>
      </v-card>
    </v-dialog>
</template>

<script>
import Register from '@/components/register/Register';
export default {
    components: { Register },
    props: ['regConfig', 'title', 'disabled'],
    name: 'AddContractDialog',
    data() {
        return {
            dialog: false,
        }
    }
}
</script>