<template>
    <section>
        <h2>{{ title }}</h2>
        <div v-if="!localContracts.length">
          Список договоров пуст.
        </div>
        <div v-for="(contract, index) in localContracts" :key="contract.id" class="d-flex align-start">
            <ContractEntry class="contract_entry" :entry='contract' :selectMode="true" ></ContractEntry>
            <button
                type="button" 
                @click="deleteContract(index)"
                class="ml-5"
                >
                <v-icon dense>
                    mdi-trash-can-outline
                </v-icon>
            </button>
        </div>
        <AddContractDialog :disabled="disabled" @select="addContract" :title="title" :regConfig="config"></AddContractDialog>
      </section>
</template>

<script>
import ContractEntry from '@/components/register/ContractEntry';
import AddContractDialog from './AddContractDialog'
export default {
    name: 'Contracts',
    components: { AddContractDialog, ContractEntry },
    props: ['title', 'contracts', 'config', 'disabled'],
    data() {
        return {
            localContracts: this.contracts || []
        }
    },
    methods: {
        addContract(e) {
            this.localContracts.push(e);
            this.$emit('changeContracts', this.localContracts);
        },
        deleteContract(delIndex) {
            this.localContracts = this.localContracts.filter((_, index) => index != delIndex)
            this.$emit('changeContracts', this.localContracts);
        }
    }

}
</script>

<style scoped>
    .contract_entry {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border: 1px solid #E3EBFC;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 10px;
    }
</style>